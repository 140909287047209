import React from 'react'
import richGnomek from '../../img/richGnomek.png';
import { ProgressBar } from 'react-loader-spinner';

const ResultWait = () => {
  return (
    <div className='w-full full-height-minus-5rem bg-lightBeige flex flex-col items-center justify-start'>
      <p className='text-4xl my-6'>
        Trwa przetwarzanie <span className='text-bronze font-semibold'>Twoich</span> odpowiedzi!
      </p>
      <div className='h-max w-2/5 flex items-center justify-center mt-10'>
        <img
          src={richGnomek}
          alt="gnomek"
          className='w-2/3'
          style={{ transform: 'rotateY(180deg)' }}
        />
      </div>

      <p className='text-3xl text-maroon mt-6 font-medium'>In progress...</p>
      <div className='w-full flex items-center justify-center mt-[-70px]'>
        <ProgressBar
          visible={true}
          height="250"
          width="350"
          borderColor="#812329"
          barColor="#AA8639"
          ariaLabel="progress-bar-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    </div>
  )
}

export default ResultWait
