import React, { useEffect, useState } from 'react'
import ResultWait from './ResultWait';
import ResultBTFinal from './ResultBTFinal';


const ResultBT = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);
  return (
    <div>
      {loading ? (
        <ResultWait />
      ) : (
        <ResultBTFinal/>
      )}
    </div>
  )
}

export default ResultBT
