import React, { useCallback, useMemo, useState } from 'react';
import { RxCross2 } from "react-icons/rx";
import { FaCheck } from "react-icons/fa";
import { GoArrowRight } from "react-icons/go";
import { BackendClient } from '../../api/api';

const questions = [
  "Zakończyłem kurs",
  "Przestał mnie interesować",
  "Zbyt wysoki poziom",
  "Zbyt niski poziom",
  "Niska jakość kursu",
];

const FinishCourse = ({ closeFinishCourse, continiue, courseId, courseName }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [other, setOther] = useState('');
  const backendClient = useMemo(() => new BackendClient(), []);
  const userId = localStorage.getItem('userId');

  const reasonSubmit = useMemo(() => {
    if(selectedOption === "Inne") {
      return other;
    } else {
      return selectedOption;
    }
  }, [selectedOption, other]);

  const handleOther = (e) => {
    setOther(e.target.value);
  };

  const handleCheck = (item) => {
    if (item === selectedOption) {
      setSelectedOption(null); // Deselect if clicked again
    } else {
      setSelectedOption(item); // Select the new option
    }
  };

  const submitFinish = useCallback(async () => {
    try {
      const response = await backendClient.finishCourse({
        courseId: courseId,
        userId: userId,
        reason: reasonSubmit,
      });
      console.log(response);
      continiue(courseId, courseName);
    } catch (error) {
      console.log(error);
    }
  }, [reasonSubmit, backendClient, continiue, courseId, courseName, userId]);

  return (
    <div className='fixed top-0 left-0 w-[100vw] h-[100vh] bg-[#f5e6cf55] backdrop-blur-sm flex items-center justify-center z-30'>
      <div className='bg-white p-6 rounded-lg w-[500px] h-max shadow-2xl'>
        <div className='w-full border-b border-lightBeige flex justify-end'>
          <RxCross2
            className='w-5 h-5 cursor-pointer mb-2'
            onClick={() => closeFinishCourse()}
          />
        </div>

        <div className='mt-10 w-full'>
          <div className='w-1/4 border-b-2 border-bronze'></div>
          <h2 className='mt-6 text-3xl font-light'>Zakończ <span className='text-bronze'>kurs</span></h2>
        </div>

        <div className='mt-4'>
          <p className='text-lg mb-3'>Wybierz powód zakończenia kursu?</p>
          {questions.map((question, index) => (
            <div key={index} className="mb-4 flex font-light items-center">
              <input
                type="radio"
                className='hidden'
                id={`checkbox-${index}`}
                checked={selectedOption === question}
                onChange={() => handleCheck(question)}
              />
              <label
                htmlFor={`checkbox-${index}`}
                className={`w-6 h-6 border-[3px] border-gold rounded flex items-center justify-center cursor-pointer`}
              >
                {selectedOption === question && <FaCheck className='text-gold w-5 h-5' />}
              </label>
              <span className="ml-2">{question}</span>
            </div>
          ))}
          <div className='flex gap-2'>
            <label
              htmlFor={`checkbox-Inne`}
              onClick={() => handleCheck('Inne')}
              className='w-6 h-6 border-[3px] border-gold rounded flex items-center justify-center cursor-pointer'
            >
              <input
                type="radio"
                className='hidden'
                id={`checkbox-inne`}
                checked={selectedOption === 'Inne'}
                readOnly
              />
              {selectedOption === 'Inne' ? (
                <FaCheck className='text-gold w-5 h-5' />
              ) : (
                <FaCheck className='text-white w-5 h-5' />
              )}
            </label>
            <p className='font-light p-0'>Inne</p>
            <textarea
              value={other}
              onChange={handleOther}
              rows={5}
              disabled={selectedOption !== "Inne"}
              placeholder='Twoja odpowiedź'
              className='border-2 border-gold rounded w-full font-light text-sm p-2 focus:outline-none focus:border-bronze disabled:border-[#ccc]'
            ></textarea>
          </div>
        </div>

        <div className='flex justify-end mt-6'>
          <div
            className='px-6 py-3 w-max bg-maroon rounded-md text-white hover:bg-maroonHover animateSl flex gap-2 items-center'
            onClick={() => submitFinish()}
          >
            <p className='font-light text-sm'>Dalej</p>
            <GoArrowRight className='w-5 h-5' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinishCourse;
