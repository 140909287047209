import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BackendClient } from '../../api/api';
import { FaRegStar } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { FaStarHalfStroke } from "react-icons/fa6";
import {
  TbSquareRoundedChevronRight,
  TbSquareRoundedChevronDown,
  TbSquareRoundedChevronUp
} from "react-icons/tb";
import { IoMdHeartEmpty } from "react-icons/io";
import SingleReview from './SingleReview';
import Review from '../popUps/Review'
import { BiMessageSquareAdd } from "react-icons/bi";


const courseObject = {
  id: 3,
  name: "",
  siteLink: "",
  courseLink: "",
  typeOfContentList: [
    {
      id: 1,
      name: "Video"
    },
  ],
  targetGroupList: [
    {
      id: 1,
      name: "Beginners"
    },
  ],
  creationDate: "2024-08-11T20:39:59.577Z",
  creatorsContact: "",
  minPrice: 0,
  maxPrice: 0,
  ratingOnGoogle: 0,
  marketingDescription: "",
  forAIDescription: "",
  trainingMode: [
    {
      id: 3,
      name: "ProduktFizyczny"
    },
  ],
  contentCategory: [
    {
      id: 1,
      name: "Programming"
    },
  ],
  benefitsOfCourse: "",
  trainingTopics: "",
  skillsDeveloped: "",
  startDate: "2024-08-11T20:39:59.577Z",
  groupSize: [
    {
      id: 1,
      name: "Small"
    },
    {
      id: 2,
      name: "Medium"
    },
    {
      id: 3,
      name: "Large"
    }
  ],
  place: "",
  softSkills: [
    {
      id: 1,
      name: "Communication"
    },
  ],
  formOfKnowledge: [
    {
      id: 1,
      name: "Lecture"
    },
  ],
  becomeAfterTraining: "",
  levelOfAdvancement: [
    {
      id: 1,
      name: "Beginner"
    },
  ],
  imageId: 0,
  logInCourseLink: "",
};

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};


const renderStars = (rating) => {
  const totalStars = 5;
  const roundedRating = Math.round(rating * 2) / 2;
  const stars = [];

  for (let i = 0; i < totalStars; i++) {
    if (i < roundedRating) {
      if (i + 0.5 === roundedRating) {
        stars.push(<FaStarHalfStroke key={i} className="w-5 h-5 text-bronze" />);
      } else {
        stars.push(<FaStar key={i} className="w-5 h-5 text-bronze" />);
      }
    } else {
      stars.push(<FaRegStar key={i} className="w-5 h-5 text-bronze" />);
    }
  }

  return stars;
};

const SingleCoursePage = () => {
  const backendClient = useMemo(() => new BackendClient(), []);
  const { courseId } = useParams();
  const [courseData, setCourseData] = useState(courseObject);
  const [showSkills, setShowSkills] = useState(false);
  const [showBenefits, setShowBenefits] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [openReview, setOpenReview] = useState(false);

  const handleShowSkills = () => {
    setShowSkills(!showSkills);
  }
  const handleShowBenefits = () => {
    setShowBenefits(!showBenefits);
  }

  const getReviewsData = useCallback(async () => {
    try {
      const response = await backendClient.getReviews(courseId);
      setReviews(response);
    } catch (error) {
      console.log(error);
    }
  }, [backendClient, courseId]);

  const getCourseData = useCallback(async () => {
    try {
      const response = await backendClient.getCourse(courseId);
      setCourseData(response);
    } catch (error) {
      console.log(error);
    }
  }, [backendClient, courseId]);

  const handleOpenReview = () => {
    setOpenReview(!openReview);
  }

  useEffect(() => {
    getCourseData();
    getReviewsData();
  }, [getCourseData, getReviewsData, openReview]);

  return (
    <div className='w-full min-height-minus-5rem flex flex-col items-center bg-lightBeige'>
      <div className='w-[1200px] relative'>
        <div className='flex py-6 gap-4'>
          <div className='w-3/5'>
            <img
              src={`https://images.weserv.nl/?url=https://api.prorokszkoleniowy.pl/images/${courseData.imageId}`}
              alt={courseData.name}
              className='w-full h-[400px] rounded-lg object-cover object-center'
            />

            <div className='mt-14'>

              <div className='border-b-2 border-bronze mb-2 w-1/5'></div>

              <h2 className='text-4xl'>Opis <span className='text-bronze'>kursu</span></h2>

              <p className='font-light text-black mt-2'>{courseData.marketingDescription}</p>

              <div className='mt-4 text-bronze text-xl cursor-pointer flex gap-2 items-center' onClick={() => handleShowSkills()}>
                <h3>Umiętności</h3>
                {showSkills ? (
                  <TbSquareRoundedChevronUp />
                ) : (
                  <TbSquareRoundedChevronDown />
                )}
              </div>
              {showSkills && (
                <p className='text-sm font-light ml-4'>{courseData.skillsDeveloped}</p>
              )}
              <div className='w-fill border-b border-bronze mt-2'></div>

              <div className='mt-4 text-bronze text-xl cursor-pointer flex gap-2 items-center' onClick={() => handleShowBenefits()}>
                <h3>Owoce</h3>
                {showBenefits ? (
                  <TbSquareRoundedChevronUp />
                ) : (
                  <TbSquareRoundedChevronDown />
                )}
              </div>
              {showBenefits && (
                <p className='text-sm font-light ml-4'>{courseData.benefitsOfCourse}</p>
              )}
              <div className='w-fill border-b border-bronze mt-2'></div>

              <div className='mt-16'>
                <div className='border-b-2 border-bronze mb-2 w-1/5'></div>
                <div className='flex justify-between'>
                  <h2 className='text-4xl'>Opinie <span className='text-bronze font-medium'>uczestników ({reviews.length})</span></h2>
                  <div
                    onClick={() => handleOpenReview()}
                    className='flex items-center justify-center gap-2 bg-maroon hover:bg-maroonHover animateSl text-white font-light px-3 py-1 rounded'>
                    <BiMessageSquareAdd className='w-5 h-5' />
                    <p>Dodaj opinię</p>
                  </div>
                </div>
                <div className=''>
                  {reviews.map(item => (
                    <div key={item.id}>
                      <SingleReview review={item} />
                    </div>
                  ))}
                </div>
              </div>

            </div>
          </div>



          {/* sticky window */}
          <div className='w-2/5 p-4'>

            <div className='sticky top-24'>
              <div className='flex justify-between'>
                <div className='flex items-center gap-1'>
                  <p className='bg-bronze rounded px-1.5 py-0.5 text-white text-sm mr-1 min-w-6 flex justify-center'>
                    {courseData.ratingOnGoogle}
                  </p>
                  {renderStars(courseData.ratingOnGoogle)}
                </div>
                <div className='px-4 py-1.5 font-light text-bronze border border-bronze rounded flex items-center gap-2 hover:text-maroon hover:border-gold animateSl'>
                  <p className='text-bronze'>Dodaj do ulubionych</p>
                  <IoMdHeartEmpty className='w-6 h-6' />
                </div>
              </div>

              <div className='flex gap-2 mt-2 font-light text-sm'>
                {courseData.contentCategory.slice(0, 4).map(cat => (
                  <p key={cat.id}>
                    {cat.name.toLocaleUpperCase()}
                  </p>
                ))}
              </div>

              <h3 className='font-medium text-2xl mt-4'>{courseData.name}</h3>

              <p className='mt-2 font-light'>{truncateText(courseData.benefitsOfCourse, 100)}</p>

              <div className='border border-bronze rounded mt-4 flex flex-col justify-center'>
                <div className='flex p-4 justify-between items-center'>
                  <div className='flex items-center gap-4'>
                    {courseData.maxPrice !== courseData.minPrice && (
                      <p className='line-through fron-light text-[#888]'>
                        {courseData.maxPrice}zł
                      </p>
                    )}
                    <p className='text-2xl text-bronze font-semibold'>
                      {courseData.minPrice}zł
                    </p>
                  </div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={courseData.logInCourseLink}
                    className='flex items-center text-lg font-light gap-2 bg-maroon text-white rounded hover:bg-maroonHover animateSl py-1 px-4'
                  >
                    <p>Kup szkolenie</p>
                    <TbSquareRoundedChevronRight className='w-5 h-5' />
                  </a>
                </div>
                {courseData.maxPrice !== courseData.minPrice && (
                  <div className='w-full bg-tan p-0.5 rounded-b-sm border-t border-bronze flex justify-center'>
                    <p className='font-semibold text-sm text-[#555]'>To szkolenie kupisz <span className='text-maroon'>{courseData.maxPrice - courseData.minPrice}zł taniej</span>!</p>
                  </div>
                )}
              </div>
            </div>
          </div>

        </div>
      </div>

      {openReview && (
        <Review closeReview={handleOpenReview} courseId={courseId} courseName={courseData.name} />
      )}
    </div>
  )
}

export default SingleCoursePage
