import React, { useCallback, useMemo, useState } from 'react'
import { RxCross2 } from "react-icons/rx";
import { GoArrowRight } from "react-icons/go";
import { IoMailOutline } from "react-icons/io5";
import dialog from '../../img/dialog.png';
import { BackendClient } from '../../api/api';


const Contact = ({ closeContact }) => {
  const [messSent, setMessSent] = useState(false);
  const backendClient = useMemo(() => new BackendClient(), []);
  const [title, setTitle] = useState('');
  const [question, setQuestion] = useState('');

  const handleTitle = (e) => {
    setTitle(e.target.value);
  }
  const handleQuestion = (e) => {
    setQuestion(e.target.value);
  }

  const handleMess = useCallback(() => {
    setMessSent((prev) => !prev);
  }, []);

  const sendEmailMessage = useCallback(async () => {
    if (title.length < 3 || question.length < 5) {
      return;
    }
    try {
      const response = await backendClient.sendEmail({
        subject: title,
        message: question,
      });
      console.log(response);
      handleMess();
    } catch (error) {
      console.log(error);
    }
  }, [backendClient, title, question, handleMess]);


  return (
    <div className='fixed top-0 left-0 w-[100vw] h-[100vh] bg-[#f5e6cf55] backdrop-blur-sm flex items-center justify-center z-30 text-black'>
      <div className='bg-white p-6 rounded-lg w-[90vw] h-max shadow-2xl overflow-hidden'>
        <div className='w-full border-b border-lightBeige flex justify-end'>
          <RxCross2
            className='w-5 h-5 cursor-pointer mb-2'
            onClick={() => closeContact()}
          />
        </div>

        {messSent ? (
          <div className='mt-8  lg:w-2/3 h-[350px] flex items-center justify-center'>
            <div>
              <div className='border-b-2 border-bronze w-1/3'></div>
              <h1 className='text-4xl mt-4'>Twoja wiadomość została <span className='text-bronze'>wysłana</span>!</h1>
              <p className='font-light mt-2'>Odpowiemy na nią najszybciej to jest możliwe.</p>
            </div>
          </div>
        ) : (
          <div className='md:h-[350px]'>
            <div className='mt-8 md:w-max '>
              <div className='border-b-2 border-bronze w-1/3'></div>
              <h1 className='text-4xl mt-4'>Masz jakieś <span className='text-bronze'>pytania</span>?</h1>
              <p className='font-light text-xl mt-4'>Skontaktuj się z nami.</p>
              <p className='font-light mt-2'>Udzielimy odpowiedź na wszystkie pytania, które Cię nurtują.</p>
            </div>

            <div className='w-full lg:w-[65%] mt-4 flex flex-col md:flex-row gap-8'>
              <div className='flex flex-col gap-2 md:w-2/3'>
                <input
                  className='border-2 border-bronze rounded w-full p-2 focus:outline-none focus:border-maroon font-light'
                  type="text"
                  placeholder='Temat wiadomści'
                  value={title}
                  onChange={handleTitle}
                />
                <textarea
                  className='border-2 border-bronze rounded w-full p-2 focus:outline-none focus:border-maroon font-light'
                  rows="3"
                  placeholder='Napisz wiadomość'
                  value={question}
                  onChange={handleQuestion}
                >
                </textarea>
              </div>
              <div className='text-sm font-light'>
                <p className='font-semibold'>Pro<span className='text-bronze'>Rok</span> P.S.A</p>
                <p>Rynek Główny 30/12, 31-010 Kraków</p>
                <p>KRS 0001098569  NIP: 6762666491</p>
                <div className='mt-2'>
                  {/* <div className='flex gap-2 items-center'>
                    <FiPhone className='text-bronze w-4 h-4' />
                    <a href="tel:48000000000" className='hover:text-maroon animateSl'>+48 000-000-000</a>
                  </div> */}
                  <div className='flex gap-2 items-center mt-1'>
                    <IoMailOutline className='text-bronze w-4 h-4' />
                    <a href="mailto:kontakt@prorokszkoleniowy.pl" className='hover:text-maroon animateSl'>kontakt@prorokszkoleniowy.pl</a>
                  </div>
                </div>
              </div>
            </div>

            <div className=' w-2/3 mt-4'>
              <div className='flex justify-center w-min lg:w-2/3'>
                <div
                  onClick={() => sendEmailMessage()}
                  className='bg-bronze rounded py-1 px-4 text-lg w-max text-white flex gap-2 items-center hover:bg-gold animateSl'>
                  <p>Wyślij</p>
                  <GoArrowRight />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className='h-[180px] md:h-[300px] lg:h-[200px] w-full relative hidden sm:block'>
          <div className='w-[70%]'>
            <img
              src={dialog}
              alt="dialog"
              className='absolute right-[150px] lg:right-[200px] top-[0] lg:top-[-200px] w-[170px] lg:w-[210px]'
            />
            <p className='absolute right-[190px] lg:right-[260px] top-[5px] lg:top-[-190px] font-medium text-center'>
              Pytania?!<br />
              <div className='text-sm flex text-center leading-5 mt-1'>Kto pyta -<br /> nie błądzi! :)</div>
            </p>
          </div>
          <img
            src="https://images.weserv.nl/?url=https://api.prorokszkoleniowy.pl/images/50&n=false"
            alt="gnomek"
            className='absolute w-[240px] lg:w-[320px] right-[-15px] bottom-[-20px] lg:bottom-[-50px]'
            style={{ transform: 'rotateY(180deg)' }}
          />
        </div>

      </div>
    </div>
  )
}

export default Contact
