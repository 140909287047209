import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CourseBox from '../courses/CourseBox';
import { TbSquareRoundedChevronRight, TbSquareRoundedChevronLeft } from "react-icons/tb";
import { BackendClient } from '../../api/api';
import Attendance from './Attendance';


const HomeCourses = () => {
  const [startIndex, setStartIndex] = useState(0);
  const [coursesFromApi, setCoursesFromApi] = useState([]);
  const backendClient = useMemo(() => new BackendClient(), []);
  const userId = localStorage.getItem('userId');

  const getCourses = useCallback(async () => {
    try {
      const response = await backendClient.getCoursesMatched(userId, "MATCHED");
      setCoursesFromApi(response[0].courses || []);
    } catch (error) {
      console.log('error:', error);
    }
  }, [backendClient, userId]);

  const moveCourse = (direction) => {
    const totalCourses = coursesFromApi.length;

    if (totalCourses === 0) return;

    let newStartIndex = startIndex;
    if (direction === 'right') {
      newStartIndex = (startIndex + 1) % totalCourses;
    } else if (direction === 'left') {
      newStartIndex = (startIndex - 1 + totalCourses) % totalCourses;
    }

    setStartIndex(newStartIndex);
  };

  const coursesToShow = coursesFromApi.length > 0 ? [
    coursesFromApi[startIndex],
    coursesFromApi[(startIndex + 1) % coursesFromApi.length],
  ] : [];

  useEffect(() => {
    getCourses();
  }, [getCourses])
  return (
    <div>
      <h2>Frekwencja</h2>
      <div>
        <Attendance />
      </div>
      <h2 className='mt-4'>Nowe kursy, które mogą Ci się spodobać</h2>
      <div className='w-full border border-[#AA863944] mt-2 p-2 rounded flex items-center mb-16 md:mb-0'>


        {coursesToShow.length > 0 ? (
          <>
            <TbSquareRoundedChevronLeft
              className='h-7 w-7 text-maroon cursor-pointer w-[8%] md:w-auto'
              onClick={() => moveCourse('right')}
            />
            <div className='hidden md:flex flex-wrap w-full justify-center'>
              {coursesToShow.map((course, index) => (
                <CourseBox key={index} course={course} isCompact={true} />
              ))}
            </div>
            <div className='flex md:hidden w-[84%] md:w-full justify-center'>
              <CourseBox course={coursesToShow[0]} isCompact={true} />
            </div>
            <TbSquareRoundedChevronRight
              className='h-7 w-7 text-maroon cursor-pointer w-[8%] md:w-auto'
              onClick={() => moveCourse('left')}
            />
          </>
        ) : (
          <div className='h-[100px] w-full flex items-center justify-center font-light text-2xl'>
            <p>Nie masz przypisanych kursów...</p>
          </div>
        )}

      </div>
    </div>
  );
};

export default HomeCourses;
