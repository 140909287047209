import React, { useEffect, useState } from 'react';
import { TbSquareRoundedChevronRight } from "react-icons/tb";
import { HiOutlineArrowRight } from "react-icons/hi";
import { Link, useNavigate } from 'react-router-dom';
import MyCourses from './MyCourses';
import Answers from './Answers';
import Progress from './Progress';
import HomeCourses from './HomeCourses';
import Review from '../popUps/Review';
import FinishCourse from '../popUps/FinishCourse';
import panelAnsw from '../../img/panelAnsw.png'
import panelDb from '../../img/panelDb.png'
import panelPrg from '../../img/panelPrg.png'
import panelSt from '../../img/panelSt.png'


const Panel = () => {
  const userId = localStorage.getItem('userId');
  const profileId = localStorage.getItem('profileId');
  const [selWindow, setSelWindow] = useState('home');
  const [openReview, setOpenReview] = useState(false);
  const [openFinishCourse, setOpenFinishCourse] = useState(false);
  const navigate = useNavigate();
  const [reviewCourseId, setReviewCourseId] = useState(null);
  const [reviewName, setReviewName] = useState('');

  const handleWindowChange = (wind) => {
    setSelWindow(wind);
  };

  const handleOpenReview = (id, name) => {
    setReviewCourseId(id);
    setReviewName(name);
    setOpenReview(!openReview);
    setOpenFinishCourse(false);
  }

  const handleOpenFinishCourse = (id) => {
    setReviewCourseId(id);
    setOpenFinishCourse(!openFinishCourse);
    setOpenReview(false);
  }

  useEffect(() => {
    if (!userId || !profileId) {
      navigate('/login');
    }
  }, [userId, profileId, navigate]);

  return (
    <div className='w-full min-height-minus-5rem flex flex-col items-center bg-lightBeige'>

      <div className='lg:w-[1250px] w-full px-4'>

        {/* Header */}
        <div className='w-full border-b-2 border-bronze lg:px-8 lg:py-0 py-2 lg:h-[8rem] flex justify-between items-center'>
          <div>
            <h1 className='text-xl md:text-4xl'>Miło <span className='text-bronze'>Cię</span> widzieć!</h1>
            <p className='font-light mt-2 text-sm md:text-base'>Co będziemy dziś robić?</p>
          </div>
          <div>
            <Link
              to={'/courses'}
              className="px-2 md:px-4 font-light py-1 bg-maroon text-base md:text-lg text-white rounded-md cursor-pointer flex gap-1 md:gap-4 items-center justify-center"
            >
              <p>Wyszukaj kurs</p>
              <HiOutlineArrowRight />
            </Link>
          </div>
        </div>

        {/* Content */}

        <div className='flex lg:flex-row flex-col mt-2 md:mt-10 lg:px-4 lg:pb-10 '>

          {/* Left */}
          <div className='w-1/4 border-r border-maroon min-h-[500px] max-h-[600px] hidden lg:flex flex-col justify-between px-4'>
            <div className='flex flex-col gap-2'>
              <div
                onClick={() => handleWindowChange('courses')}
                className={`flex items-center justify-between py-1 px-2 rounded font-light hover:bg-bronze hover:text-white transition ease-in-out duration-300 cursor-pointer ${selWindow === 'courses' && 'bg-bronze text-white'}`}
              >
                <p>Moje kursy</p>
                <TbSquareRoundedChevronRight className='w-5 h-5' />
              </div>
              <div
                onClick={() => handleWindowChange('answers')}
                className={`flex items-center justify-between py-1 px-2 rounded font-light hover:bg-bronze hover:text-white transition ease-in-out duration-300 cursor-pointer ${selWindow === 'answers' && 'bg-bronze text-white'}`}
              >
                <p>Moje odpowiedzi</p>
                <TbSquareRoundedChevronRight className='w-5 h-5' />
              </div>
              <div
                onClick={() => handleWindowChange('progress')}
                className={`flex items-center justify-between py-1 px-2 rounded font-light hover:bg-bronze hover:text-white transition ease-in-out duration-300 cursor-pointer ${selWindow === 'progress' && 'bg-bronze text-white'}`}
              >
                <p>Moje postępy</p>
                <TbSquareRoundedChevronRight className='w-5 h-5' />
              </div>
            </div>
          </div>


          {/* navigate Right */}
          <div className='w-full lg:w-3/4 lg:min-h-[500px] lg:px-4'>

            {selWindow === 'courses' && (
              <MyCourses profileId={profileId} userId={userId} />
            )}
            {selWindow === 'answers' && (
              <Answers userId={userId} />
            )}
            {selWindow === 'progress' && (
              <Progress handleOpenReview={handleOpenReview} handleOpenFinishCourse={handleOpenFinishCourse} />
            )}
            {selWindow === 'home' && (
              <HomeCourses />
            )}

          </div>
        </div>
      </div>

      {/* navigate bottom, phone/tablet */}
      <div className='w-[100vw] bg-maroon fixed bottom-0 lg:hidden'>
        <div className='flex text-white gap-2 md:gap-10 py-1 justify-center text-xs md:text-sm'>
        <div
            onClick={() => handleWindowChange('home')}
            className={`flex flex-col items-center justify-between pt-0.5 px-1 mb-0.5 text-white pb-0.5 md:pb-1 font-light border-b-[2px] md:border-b-[3px] border-transparent hover:border-bronze transition ease-in-out duration-300 cursor-pointer ${selWindow === 'courses' && 'border-bronze'}`}
          >
            {/* <TbSquareRoundedChevronRight className='w-5 h-5' /> */}
            <img src={panelDb} alt="panelDb" className='h-6 w-6 md:w-8 md:h-8 mb-0.5 md:mb-2' />
            <p>Dashboard</p>
          </div>
          <div
            onClick={() => handleWindowChange('courses')}
            className={`flex flex-col items-center justify-between pt-0.5 px-1 mb-0.5 text-white pb-0.5 md:pb-1 font-light border-b-[2px] md:border-b-[3px] border-transparent hover:border-bronze transition ease-in-out duration-300 cursor-pointer ${selWindow === 'courses' && 'border-bronze'}`}
          >
            {/* <TbSquareRoundedChevronRight className='w-5 h-5' /> */}
            <img src={panelSt} alt="panelSt" className='h-6 w-6 md:w-8 md:h-8 mb-0.5 md:mb-2' />
            <p>Moje kursy</p>
          </div>
          <div
            onClick={() => handleWindowChange('answers')}
            className={`flex flex-col items-center justify-between pt-0.5 px-1 mb-0.5 text-white pb-0.5 md:pb-1 font-light border-b-[2px] md:border-b-[3px] border-transparent hover:border-bronze transition ease-in-out duration-300 cursor-pointer ${selWindow === 'answers' && 'border-bronze'}`}
          >
            <img src={panelAnsw} alt="panelAnsw" className='h-6 w-6 md:w-8 md:h-8 mb-0.5 md:mb-2' />
            <p>Odpowiedzi</p>
          </div>
          <div
            onClick={() => handleWindowChange('progress')}
            className={`flex flex-col items-center justify-between pt-0.5 px-1 mb-0.5 text-white pb-0.5 md:pb-1 font-light border-b-[2px] md:border-b-[3px] border-transparent hover:border-bronze transition ease-in-out duration-300 cursor-pointer ${selWindow === 'progress' && 'border-bronze'}`}
          >
            <img src={panelPrg} alt="panelPrg" className='h-6 w-6 md:w-8 md:h-8 mb-0.5 md:mb-2' />
            <p>Postępy</p>
          </div>
        </div>
      </div>
      {openReview && (
        <Review closeReview={handleOpenReview} courseId={reviewCourseId} courseName={reviewName} />
      )}
      {openFinishCourse && (
        <FinishCourse closeFinishCourse={handleOpenFinishCourse} continiue={handleOpenReview} courseId={reviewCourseId} courseName={reviewName} />
      )}
    </div>
  );
};

export default Panel;
