const filterOptions = {
  formOfKnowledge: [
    { name: "Aplikacja", id: 1 },
    { name: "Do określenia", id: 2 },
    { name: "Indywidualne szkolenia", id: 3 },
    { name: "Indywidualne wsparcie", id: 4 },
    { name: "Materiały fizyczne", id: 5 },
    { name: "Materiały online", id: 6 },
    { name: "Social learning - wymiana wiedzy między uczestnikami", id: 7 },
    { name: "Stacjonarne ćwiczenia grupowe", id: 8 },
    { name: "Webinary", id: 9 },
    { name: "Wyjścia przyrodnicze", id: 12 },
    { name: "Wykłady", id: 10 },
    { name: "Ćwiczenia grupowe online", id: 11 }
  ],
  trainingMode: [
    { name: "Hybrydowo", id: 1 },
    { name: "On-line", id: 2 },
    { name: "Produkt fizyczny", id: 3 },
    { name: "Stacjonarnie", id: 4 }
  ],
  typeOfContent: [
    { name: "Aplikacja", id: 183 },
    { name: "Artykuły", id: 197 },
    { name: "Audiobooki", id: 189 },
    { name: "Audiowizualne", id: 188 },
    { name: "Audyt", id: 199 },
    { name: "Booktube", id: 190 },
    { name: "Ebooki", id: 191 },
    { name: "Filmy YouTube", id: 192 },
    { name: "Interaktywne", id: 182 },
    { name: "Konsultacje stacjonarne", id: 200 },
    { name: "Książki", id: 195 },
    { name: "Kursy Interaktywne", id: 184 },
    { name: "Live", id: 185 },
    { name: "Mentoring", id: 201 },
    { name: "Newslettery", id: 196 },
    { name: "Podcasty", id: 193 },
    { name: "Poradniki", id: 186 },
    { name: "Tekstowe", id: 194 },
    { name: "Warsztaty", id: 202 },
    { name: "Webinary", id: 203 },
    { name: "Wykładowe", id: 187 },
    { name: "Z człowiekiem", id: 198 }
  ],
  contentCategory: [
    { name: "Analiza prawna", id: 260 },
    { name: "Bezpieczeństwo", id: 257 },
    { name: "Biznes", id: 233 },
    { name: "Branding", id: 234 },
    { name: "Budowanie relacji", id: 268 },
    { name: "Budowanie strategii", id: 251 },
    { name: "Budowanie wiarygodności firm", id: 235 },
    { name: "Case study", id: 256 },
    { name: "Coaching", id: 271 },
    { name: "Cukrzyca", id: 213 },
    { name: "Dieta i odżywianie", id: 214 },
    { name: "Diety niskowęglowodanowe", id: 215 },
    { name: "Edukacja", id: 236 },
    { name: "Edukacja prawna", id: 228 },
    { name: "Efektywność", id: 270 },
    { name: "Emerytura", id: 252 },
    { name: "Emocje", id: 265 },
    { name: "Finanse", id: 204 },
    { name: "Giełda", id: 205 },
    { name: "Giełda metali", id: 206 },
    { name: "HR", id: 237 },
    { name: "Hipoteka", id: 207 },
    { name: "Insulinooporność", id: 217 },
    { name: "Inwestowanie", id: 208 },
    { name: "Inwestycje", id: 249 },
    { name: "Komunikacja", id: 262 },
    { name: "Kosmetyki", id: 218 },
    { name: "Kredyt", id: 209 },
    { name: "Kredyty", id: 250 },
    { name: "Księgowość", id: 261 },
    { name: "Kuchnia", id: 219 },
    { name: "Kulinaria", id: 220 },
    { name: "Marketing", id: 238 },
    { name: "Materiały edukacyjne", id: 248 },
    { name: "Medycyna", id: 221 },
    { name: "Mentoring", id: 239 },
    { name: "Motywacja", id: 274 },
    { name: "Nawyki", id: 275 },
    { name: "Nawyki żywieniowe", id: 222 },
    { name: "Networking", id: 240 },
    { name: "Oszczędzanie", id: 210 },
    { name: "Plany dietetyczne i treningowe", id: 223 },
    { name: "Podatki", id: 253 },
    { name: "Prawo", id: 227 },
    { name: "Przepisy", id: 229 },
    { name: "Psychologia", id: 264 },
    { name: "RODO", id: 258 },
    { name: "Relacje społeczne", id: 247 },
    { name: "Rodzina", id: 263 },
    { name: "Rozwój duchowy", id: 276 },
    { name: "Rozwój osobisty", id: 232 },
    { name: "Rozwój własnej działalności", id: 241 },
    { name: "Samopoczucie", id: 224 },
    { name: "Sprzedaż książek", id: 211 },
    { name: "Strategie", id: 230 },
    { name: "Styl życia", id: 242 },
    { name: "Suplementacja", id: 255 },
    { name: "Suplementy diety", id: 225 },
    { name: "Szkoła wyższa", id: 259 },
    { name: "Terapia", id: 269 },
    { name: "Treningi", id: 226 },
    { name: "Tworzenie kampanii reklamowych", id: 243 },
    { name: "Tworzenie kanału na YT", id: 244 },
    { name: "Tworzenie stron internetowych", id: 245 },
    { name: "Umiejętności miękkie", id: 273 },
    { name: "Wsparcie techniczne i doradztwo", id: 231 },
    { name: "Wsparcie w prowadzeniu kanału", id: 246 },
    { name: "Wychowanie", id: 266 },
    { name: "Zarządzanie stresem", id: 272 },
    { name: "Zdrowie", id: 212 },
    { name: "Zdrowie i fitness", id: 216 },
    { name: "Zdrowie psychiczne", id: 254 },
    { name: "Związki", id: 267 }
  ]
};

export default filterOptions;
