import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BackendClient } from '../../api/api';
import dialog from '../../img/dialog.png'
import { IoMdBook } from "react-icons/io";
import { FaCheck } from "react-icons/fa";

const calendar = [];

const polishDays = ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'Sb'];

const getPolishDayOfWeek = (dateString) => {
  // Convert the date string to a Date object
  const date = new Date(dateString);

  // Get the day of the week as a number (0 for Sunday, 1 for Monday, etc.)
  const dayOfWeek = date.getDay();

  // Return the Polish name for the day of the week
  return polishDays[dayOfWeek];
};

const Attendance = () => {
  const backendClient = useMemo(() => new BackendClient(), []);
  const userId = localStorage.getItem('userId');
  const [data, setData] = useState(calendar);
  const [logInNum, setLogInNum] = useState(0);
  const [studyNum, setStudyNum] = useState(0);

  const getData = useCallback(async () => {
    try {
      const response = await backendClient.getAttendanceLastWeek(userId);
      setLogInNum(response[response.length - 1].userPresenceDaysCount);
      setStudyNum(response[response.length - 1].userStudyDaysCount);
      setData(response.slice(-7));
    } catch (error) {
      console.log(error);
    }
  }, [backendClient, userId]);

  const markStudy = useCallback(async () => {
    try {
      await backendClient.markStudyDay(userId);
      console.log('congrats, you have studied today!!!');
      getData();
    } catch (error) {
      console.log(error);
    }
  }, [backendClient, userId, getData])

  useEffect(() => {
    getData();
  }, [getData, userId, markStudy]);
  return (
    <div className='w-full border border-[#AA863944] mt-2 rounded flex items-center h-[180px] md:h-[220px]'>

      <div className='w-[65%] md:w-[40%] lg:w-[25%] relative h-full'>
        <img
          src="https://images.weserv.nl/?url=https://api.prorokszkoleniowy.pl/images/50&n=false"
          alt="gnomek"
          className='absolute bottom-2 left-1 h-[70%] md:h-3/4 z-10'
        />
        <img
          src={dialog}
          alt="dialog"
          className='absolute top-2 left-20 md:left-24 h-[55%] md:h-[45%]'
          style={{ transform: 'rotateY(180deg)' }}
        />
        <div className='absolute top-4 left-24 md:left-28 text-xs flex flex-col items-center font-light'>
          <p className='font-medium md:text-sm'>Cześć!</p>
          <p className='w-max'>Codzienna nauka to</p>
          <p>klucz do sukcesu!</p>
        </div>
      </div>

      

      <div className='w-[60%] h-full hidden lg:flex flex-col'>
        <div className='h-[60%] px-2 py-6 flex gap-1.5 justify-center'>
          {data.length > 0 && data.map((item, index) => (
            <div key={item.id} className={`h-full w-[13%] rounded flex flex-col p-2 justify-between items-center ${index === 6 && 'bg-maroon text-white'}`}>
              <p className='text-lg font-semibold'>{getPolishDayOfWeek(item.day)}</p>
              <div className={`border-2 border-bronze w-6 h-6 rounded flex text-white items-center justify-center ${item.loggedInToday ? 'bg-bronze' : 'bg-lightBeige'}`}>
                {item.didStudyToday && (
                  <IoMdBook className='w-5 h-5' />
                )}
                {(item.loggedInToday && !item.didStudyToday) && (
                  <FaCheck className='w-4 h-4' />
                )}
              </div>
            </div>
          ))}

        </div>

        <div className='h-[40%] flex items-center justify-center'>
          <div
            onClick={() => markStudy()}
            className='flex items-center justify-center bg-bronze px-4 py-1 rounded gap-4 text-white hover:bg-gold animateSl'>
            <div
              className='border border-white rounded w-5 h-5 flex items-center justify-center'>
              <IoMdBook className='w-4 h-4' />
            </div>
            <p>Oznacz jako<br />dzień nauki</p>
          </div>
        </div>
      </div>

      <div className='w-[35%] md:w-3/5 lg:w-[15%] p-2 flex flex-col md:flex-row lg:flex-col gap-2 border-l border-[#AA863944] h-full justify-center'>
        <div className=' flex flex-col items-center justify-center w-auto md:w-1/2 lg:w-auto'>
          <p className='lg:text-base text-base md:text-xl font-light'>Logujesz się</p>
          <p className='lg:text-4xl text-4xl md:text-5xl'>{logInNum}</p>
          <p className='lg:text-xs text-xs md:text-lg font-light'>dni z rzędu</p>
        </div>
        <div className='border-b w-full border-[#AA863944] block md:hidden lg:block'></div>
        <div className='md:block lg:hidden hidden border-r border-[#AA863944]'></div>
        <div className='lg:text-md text-xl  flex flex-col items-center justify-center w-auto md:w-1/2 lg:w-auto'>
          <p className='lg:text-4xl text-4xl md:text-5xl  text-bronze'>{studyNum}</p>
          <p className='lg:text-xs text-xs md:text-lg font-light'>dni nauki</p>
        </div>
      </div>

    </div>
  )
}

export default Attendance
