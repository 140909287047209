import React from 'react'
import CourseList from './CourseList';

const Courses = () => {
  return (
    <div>
      <CourseList />
    </div>
  )
}

export default Courses
