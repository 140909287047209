import React from 'react'
import gnomek from '../../img/gnomek.png';
import dialog from '../../img/dialog.png';
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const ResultBTFinal = () => {
  const {result, profileId} = useParams();

  // dialog box text to insert
  const resultText = () => {
    if (result === 'CAREER_ADVISOR') {
      return (
        <p className='absolute top-3 left-[24%] text-lg font-medium text-center'>
          Oh...<br />
          <div className='text-sm flex text-center leading-5 mt-1'>Ktoś jest tutat bardzo<br />niezdecydowany...</div>
        </p>
      )
    } else if (result === 'PSYCHOLOGIST') {
      return (
        <p className='absolute top-3 left-[20%] text-lg font-medium text-center'>
          Oh...<br />
          <div className='text-sm flex text-center leading-5 mt-1'>
            Pamiętaj, że zdrowie<br />emocjonalne powinno<br />być na pierwszym miejscu!
          </div>
        </p>
      )
    } else if (result === 'TRAINING') {
      return (
        <p className='absolute top-3 left-[20%] text-lg font-medium text-center'>
          Huraaaa!<br />
          <div className='text-sm flex text-center leading-5 mt-1'>
            Pora na przygodę, w świat<br />nauki!
          </div>
        </p>
      )
    } else {
      return;
    }
  }

  // test result text to insert
  const resultTextFull = () => {
    if (result === 'CAREER_ADVISOR') {
      return (
        <>
          <span className='text-2xl font-semibold'>Cześć! </span>
          <br /> <br />
          Wygląda na to, że bardziej niż nas, potrzebujesz dowiedzieć się w jakim kierunku powinieneś rozwinąć się zawodowo!
          <br /> <br />
          Skontaktuj się z tymi doradcami zawodowymi, oni powiedzą Ci jak rozwinąć się dalej ;)
        </>
      )
    } else if (result === 'PSYCHOLOGIST') {
      return (
        <>
          <span className='text-2xl font-semibold'>Cześć! </span>
          <br /> <br />
          mamy wrażenie, że żeby jeszcze lepiej rozwijać się zawodowo, potrzebny Ci będzie człowiek od pracy z własnym mentalem!
          <br /> <br />
          Zobacz tutaj, żeby znaleźć takiego!
        </>
      )
    } else if (result === 'TRAINING') {
      return (
        <>
        <span className='text-2xl font-semibold'>Huraaaaa! </span>
        <br /> <br />
        Jesteś gotowy na przygodę z ProRokiem :)
        <br /> <br />
        Wypełnij ankietę, a nasz innowacyjny system przypisze co Ciebie najlepiej dopasowane szkolenia!
      </>
      )
    } else {
      return
    }
  }
  return (
    <div className='w-full full-height-minus-5rem bg-lightBeige flex flex-col items-center justify-center'>
      <div className='h-[500px] w-90vw flex lg:w-[1000px]'>
        <div className='h-full w-2/5 relative hidden sm:block'>

          {/* Dialog block */}
          <div className='w-[70%] ml-10'>
            <img
              src={dialog}
              alt="dialog"
            />
            {resultText()}
          </div>

          {/* Gnomek */}
          <img
            src={gnomek}
            alt="gnomek"
            className='absolute w-72 top-32 left-[40%] z-10 rotate-[-25deg] animate-rotate-in-down-right'
          />
        </div>

        <div className='h-full w-full sm:w-3/5 border-l-2 border-bronze bg-lightBeige px-8 relative z-20 flex flex-col items-left justify-center'>

          {/* Header */}
          <div>
            <h1 className='text-5xl'>
              Wyniki już są <span className='font-semibold text-bronze'>Gotowe!</span>
            </h1>
            <div className='w-3/4 border-b-2 border-bronze my-4'></div>
          </div>

          {/* Result text */}
          <p className='text-lg'>
            {resultTextFull()}
          </p>

          {/* Buttons */}
          <div>

            {/* Hurra button :) */}
            {result === 'TRAINING' ? (
              <div className='mt-16'>
                <Link
                  className='h-10 w-max bg-maroon flex items-center px-6 rounded font-normal gap-4 cursor-pointer hover:bg-[#A63C43] transition ease-in-out duration-300 text-white'
                  to={`/expanding-test/${profileId}`}
                >
                  <p>
                    Wypełnij ankietę
                  </p>
                  <FaArrowRightLong />
                </Link>
              </div>
            ) : (

              // not hurraaa buttons :(
              <div className='flex justify-between mt-16'>
                <Link
                  className='w-max flex items-center rounded font-medium gap-4 cursor-pointer hover:text-bronze transition ease-in-out duration-300 text-maroon'
                  to={'/basic-test'}
                >
                  <p>
                    Wypełnuj test ponownie
                  </p>
                </Link>

                <Link
                  className='h-10 w-max bg-maroon flex items-center px-6 rounded font-normal gap-4 cursor-pointer hover:bg-[#A63C43] transition ease-in-out duration-300 text-white'
                  to={'/basic-test'}
                >
                  <p>
                    Skontaktuj się z {result === 'CAREER_ADVISOR' ? 'doradcą' : 'psyhologiem'}
                  </p>
                  <FaArrowRightLong />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResultBTFinal
