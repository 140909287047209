import React, { useEffect, useRef, useState } from 'react';
import headerLogo from '../img/headerLogo.png';
import profilePic from '../img/profilePic.png';
import { Link, useLocation } from 'react-router-dom';
import { SlArrowDown } from "react-icons/sl";
import { BiLogOut } from "react-icons/bi";
import { BackendClient } from '../api/api';
import MyProfile from './popUps/MyProfile';
import Settings from './popUps/Settings';
import Contact from './popUps/Contact';
import { FiMenu } from "react-icons/fi";


const Header = () => {
  const backendClient = new BackendClient();
  const [popUp, setPopUp] = useState(false);
  const userId = localStorage.getItem('userId');
  const profileId = localStorage.getItem('profileId');
  const userName = localStorage.getItem('userName');
  const [openProfile, setOpenProfile] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const popUpRef = useRef(null);
  const toggleButtonRef = useRef(null);
  const location = useLocation();

  const handlePanelClick = (e) => {
    if (location.pathname === '/panel') {
      e.preventDefault();
      window.location.reload();
    }
  };

  const handleCoursesClick = (e) => {
    if (location.pathname === '/courses') {
      e.preventDefault();
      window.location.reload();
    }
  };

  const handleOpenProfile = () => {
    setOpenProfile(!openProfile);
  };

  const handleOpenSettings = () => {
    setOpenSettings(!openSettings);
  };

  const handleOpenContact = () => {
    setOpenContact(!openContact);
  };

  const handlePopUp = () => {
    setPopUp((prev) => !prev);
  };

  const handleLogOut = async () => {
    try {
      await backendClient.logout();
    } catch (error) {
      console.log('error:', error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popUpRef.current &&
        !popUpRef.current.contains(event.target) &&
        !toggleButtonRef.current.contains(event.target)
      ) {
        setPopUp(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popUp]);

  return (
    <div className='w-[100vw] h-18 lg:h-20 bg-[#770109] py-3 lg:py-4 flex justify-between items-center text-white fixed z-30 bg-opacity-80 backdrop-blur-lg'>
      <div className='flex justify-between items-center lg:w-[1200px] w-full px-4 lg:px-0 mx-auto'>
        <Link className='flex gap-2 items-center w-max cursor-pointer'
          onClick={handlePanelClick}
          to={'/panel'}>
          <img src={headerLogo} alt="headerLogo" className='lg:h-14 h-12' />
          <p className='text-xl font-semibold'>
            <span className=''>Pro</span>
            <span className='text-bronze'>Rok</span>
          </p>
        </Link>
        <div className='hidden lg:flex gap-8 w-max justify-center pt-2'>
          {profileId && (
            <>
              <Link
                to={'/panel'}
                onClick={handlePanelClick}
                className='cursor-pointer border-b-2 border-transparent pb-2 font-light hover:border-b-2 hover:border-gold transition ease-in-out duration-300'>
                Mój panel
              </Link>
              <Link
                to={'/courses'}
                onClick={handleCoursesClick}
                className='cursor-pointer border-b-2 border-transparent pb-2 font-light hover:border-b-2 hover:border-gold transition ease-in-out duration-300'>
                Dostępne kursy
              </Link>
              <p
                onClick={() => handleOpenContact()}
                className='cursor-pointer border-b-2 border-transparent pb-2 font-light hover:border-b-2 hover:border-gold transition ease-in-out duration-300'>
                Kontakt
              </p>
            </>
          )}
        </div>
        <div className='w-max flex justify-end'>
          <div className='flex gap-2 items-center relative'>
            {profileId && userId ? (
              <div
                className='pl-2 lg:border-l border-white flex gap-2 items-center h-10 cursor-pointer'
                onClick={handlePopUp}
                ref={toggleButtonRef}
              >
                <div className='flex font-light'>

                  <div className='hidden lg:flex gap-1 items-center '>
                    <img src={profilePic} alt="profilePic" className='h-6 w-6' />
                    <p>Witaj</p>
                    <p>{userName}</p>
                    <SlArrowDown className='ml-1' />
                  </div>
                  <div className='flex gap-4 items-center pl-16 lg:hidden'>
                    <p>Menu</p>
                    <FiMenu className='w-5 h-5' />
                  </div>
                  {popUp && (
                    <div ref={popUpRef} className='lg:absolute fixed py-1 px-1 lg:top-12 top-16 lg:left-1 right-0 left-0 lg:w-full w-[100vw] bg-maroonHover rounded z-10'>
                      <Link to={"/panel"} onClick={handlePanelClick} className='lg:hidden w-full py-1 px-2 bg-maroonHover flex items-center gap-2 hover:bg-maroon mt-2 rounded animateSl'>
                        <p>Mój panel</p>
                      </Link>
                      <Link to={"/courses"} onClick={handleCoursesClick} className='lg:hidden w-full py-1 px-2 bg-maroonHover flex items-center gap-2 hover:bg-maroon mt-2 rounded animateSl'>
                        <p>Dostępne kursy</p>
                      </Link>
                      <div onClick={() => handleOpenContact()} className='lg:hidden w-full py-1 px-2 bg-maroonHover flex items-center gap-2 hover:bg-maroon mt-2 rounded animateSl'>
                        <p>Kontakt</p>
                      </div>
                      <div onClick={handleOpenProfile} className='w-full py-1 px-2 bg-maroonHover flex items-center gap-2 hover:bg-maroon mt-2 rounded animateSl'>
                        <p>Mój profil</p>
                      </div>
                      <div onClick={handleOpenSettings} className='w-full py-1 px-2 bg-maroonHover flex items-center gap-2 hover:bg-maroon mt-2 rounded animateSl'>
                        <p>Ustawienia</p>
                      </div>
                      <div className='border-t border-white mt-2 mx-2'></div>
                      <div onClick={handleLogOut} className='w-full py-1 px-2 bg-maroonHover flex items-center gap-2 hover:bg-maroon mt-2 rounded animateSl'>
                        <BiLogOut />
                        <p>Wyloguj się</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <Link className='pl-4 border-l border-white flex gap-4 items-center h-10 cursor-pointer' to={'/login'}>
                <img src={profilePic} alt="profilePic" className='h-6' />
                <p>Zaloguj się</p>
              </Link>
            )}
          </div>
        </div>
      </div>
      {openProfile && <MyProfile closeProfile={handleOpenProfile} />}
      {openSettings && <Settings closeSettings={handleOpenSettings} />}
      {openContact && <Contact closeContact={handleOpenContact} />}
    </div>
  );
};

export default Header;
