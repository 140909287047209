import React, { useCallback, useMemo, useState } from 'react'
import { RxCross2 } from "react-icons/rx";
import { IoSettingsOutline } from "react-icons/io5";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { BackendClient } from '../../api/api';

const Settings = ({ closeSettings }) => {
  const [passOld, setPassOld] = useState('');
  const [passNew, setPassNew] = useState('');
  const [passNewR, setPassNewR] = useState('');
  const [closeOld, setCloseOld] = useState('');
  const [closeNew, setCloseNew] = useState('');
  const [closeNewR, setCloseNewR] = useState('');
  const backendClient = useMemo(() => new BackendClient(), []);
  const [success, setSuccess] = useState(false);

  const handleSuccess = useCallback(() => {
    setSuccess(!success);
  }, [success])

  const handleCloseOld = () => {
    setCloseOld(!closeOld);
  }
  const handleCloseNew = () => {
    setCloseNew(!closeNew);
  }
  const handleCloseNewR = () => {
    setCloseNewR(!closeNewR);
  }

  const handlePassOld = (e) => {
    setPassOld(e.target.value);
  }
  const handlePassNew = (e) => {
    setPassNew(e.target.value);
  }
  const handlePassNewR = (e) => {
    setPassNewR(e.target.value);
  }

  const sendEmailMessage = useCallback(async () => {
    try {
      const response = await backendClient.changePassword({
        oldPassword: passOld,
        newPassword: passNew,
        newPasswordAgain: passNewR
      });
      console.log(response);
      handleSuccess();
    } catch (error) {
      console.log(error);
    }
  }, [backendClient, handleSuccess, passNew, passNewR, passOld]);


  return (
    <div className='fixed top-0 left-0 w-[100vw] h-[100vh] bg-[#f5e6cf55] backdrop-blur-sm flex items-center justify-center z-30 text-black'>
      <div className='bg-white p-3 md:p-6 rounded-lg w-[90vw] md:w-[500px] h-max shadow-2xl'>
        <div className='w-full border-b border-lightBeige flex justify-end'>
          <RxCross2
            className='w-5 h-5 cursor-pointer mb-2'
            onClick={() => closeSettings()}
          />
        </div>

        <div className='mt-6 w-full'>
          <div className='flex gap-2 items-center'>
            <IoSettingsOutline className='text-bronze h-6 w-6' />
            <h2 className='text-2xl md:text-3xl font-light'>Ustawienia <span className='text-bronze'>profilu</span></h2>
          </div>
          <div className='w-1/4 border-b-2 border-bronze mt-4'></div>
        </div>

        {success ? (
          <div className='h-[150px] flex flex-col md:items-center justify-center'>
            <h1 className='text-lg md:text-xl tems-light'>Hasło zostało zmienione!</h1>
            <div className='w-1/4 border-b-2 border-bronze my-2'></div>
            <p className='text-xs md:text-sm font-light'>Już możesz się zalogować za pomocą nowego hasła :)</p>
          </div>
        ) : (
          <div className='w-full border border-tan rounded mt-4'>
            <div className='bg-lightBeige rounded border-tan p-1'>
              Zmiana hasła
            </div>
            <div className='px-2'>
              <div className='relative mt-2'>
                {closeOld ?
                  <BsEyeSlash
                    onClick={() => handleCloseOld()}
                    className='absolute top-[25%] right-1 text-bronze' />
                  : <BsEye
                    onClick={() => handleCloseOld()}
                    className='absolute top-[25%] right-1 text-bronze' />
                }
                <input
                  type={closeOld ? 'text' : 'password'}
                  className='focus:outline-none focus:border-maroon border border-bronze w-full rounded px-1 py-1 font-light text-sm'
                  placeholder='Stare hasło'
                  value={passOld}
                  onChange={handlePassOld}
                />
              </div>
              <div className='relative my-2'>
                {closeNew ?
                  <BsEyeSlash
                    onClick={() => handleCloseNew()}
                    className='absolute top-[25%] right-1 text-bronze' />
                  : <BsEye
                    onClick={() => handleCloseNew()}
                    className='absolute top-[25%] right-1 text-bronze' />
                }
                <input
                  type={closeNew ? 'text' : 'password'}
                  className='focus:outline-none focus:border-maroon border border-bronze w-full rounded px-1 py-1 font-light text-sm'
                  placeholder='Nowe hasło'
                  value={passNew}
                  onChange={handlePassNew}
                />
              </div>
              <div className='relative my-2'>
                {closeNewR ?
                  <BsEyeSlash
                    onClick={() => handleCloseNewR()}
                    className='absolute top-[25%] right-1 text-bronze' />
                  : <BsEye
                    onClick={() => handleCloseNewR()}
                    className='absolute top-[25%] right-1 text-bronze' />
                }
                <input
                  type={closeNewR ? 'text' : 'password'}
                  className='focus:outline-none focus:border-maroon border border-bronze w-full rounded px-1 py-1 font-light text-sm'
                  placeholder='Powtórz nowe hasło'
                  value={passNewR}
                  onChange={handlePassNewR}
                />
              </div>
            </div>
          </div>
        )}

        <div className='flex gap-2 justify-end text-xs mt-4'>
          <div
            onClick={() => closeSettings()}
            className='py-2 px-3 text-maroon hover:text-maroonHover animateSl'>Anuluj</div>
          <div
            onClick={() => sendEmailMessage()}
            className='py-2 px-3 text-white bg-maroon rounded hover:bg-maroonHover animateSl'>Zapisz zmiany</div>
        </div>

      </div>
    </div>
  )
}

export default Settings
