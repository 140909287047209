import React, { useState } from 'react'
import SignUpBG from '../../img/SignUpBG.png'
import { FaArrowRightLong } from "react-icons/fa6";

const Offer = ({ handleQuery }) => {
  const [query, setQuery] = useState('');
  const [error, setError] = useState('');

  const handleTextQuery = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmit = () => {
    if (query.length < 4) {
      setError('Wyszukiwanie jest możliwe po wpisaniu przynajmniej 3 znaków');
      return
    }
    handleQuery(`${query}&fuzzySearchType=levenshtein&param=3`);
    setError("");
    setQuery("");
  }
  return (
    <div className="h-[80vh] md:h-[68vh] lg:h-[100vh] pt-14 md:pt-0 flex items-center overflow-x-hidden bg-black text-white relative">
      <div className="flex items-center justify-center h-full w-full lg:w-2/3 from-black to-transparent from-30% md:from-50% lg:from-70% to-100% z-10" style={{ backgroundPosition: '78%', backgroundSize: '100%', background: 'linear-gradient(90deg, var(--tw-gradient-stops))' }}>
        <div className='w-[90%] lg:w-[70%]'>
          <h1 className='text-4xl md:text-5xl lg:text-6xl'>Sprawdź naszą ofertę, gdzie innowacja spotyka się z <span className='text-bronze'>edukacją!</span></h1>

          <div className='w-1/3 border-t-4 border-bronze my-6'></div>

          <p className='text-2xl md:text-3xl font-light'>Możesz samodzielnie poszukać kursu, który Cię interesuje.</p>

          <div className="flex flex-col md:flex-row gap-2 md:gap-8  md:items-center w-full md:w-3/4 mt-6 relative">
            <input
              type="text"
              placeholder="Wyszukaj"
              value={query}
              onChange={handleTextQuery}
              className="w-full h-12 px-4 py-2 rounded-md bg-white text-black border-2 border-bronze focus:outline-none"
            />
            <div
              className="w-min px-6 py-2 h-12 bg-maroon text-white rounded-md cursor-pointer flex gap-4 items-center justify-center"
              onClick={() => handleSubmit()}
            >
              <p>Wyszukaj</p>
              <FaArrowRightLong />
            </div>
            {/* error message */}
            {error.length > 0 && (
              <p className='text-red-500 absolute left-0 top-16 text-light text-lg'>{error}</p>
            )}
          </div>
        </div>
      </div>
      <div
        className='w-full h-full bg-cover md:bg-contain bg-no-repeat absolute right-[-30%] md:right-[-42%]'
        style={{
          backgroundImage: `url(${SignUpBG})`,
        }}
      ></div>
    </div>
  )
}

export default Offer
