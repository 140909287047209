import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BackendClient } from '../../api/api';
import { RxCross2 } from "react-icons/rx";
import { IoPersonOutline } from "react-icons/io5";
import { IoPersonCircleOutline } from "react-icons/io5";
import { IoMailOutline } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";
import { updateDemData } from '../../api/api';

const demDataInitial = [
  {
    name: 'A. Podaj swoją grupę wiekową',
    options: [
      { name: "poniej 13", value: "UP_TO_13" },
      { name: "13-18", value: "OVER_13_TO_18_INC" },
      { name: "18-24", value: "OVER_18_TO_24_INC" },
      { name: "24-35", value: "OVER_24_TO_35_INC" },
      { name: "35-45", value: "OVER_35_TO_45_INC" },
      { name: "45-60", value: "OVER_45_TO_60_INC" },
      { name: "powyej 60", value: "OVER_60" },
    ],
    key: 'ageGroup',
  },
  {
    name: 'B. Płeć',
    options: [
      { name: "kobieta", value: "FEMALE" },
      { name: "mężczyzna", value: "MALE" },
    ],
    key: 'gender',
  },
  {
    name: 'D. Status zawodowy',
    options: [
      { name: "bezrobotny", value: "UNEMPLOYED" },
      { name: "student/uczeń", value: "STUDENT" },
      { name: "pracownik etatowy", value: "FULL_TIME_EMPLOYEE" },
      { name: "samozatrudniony", value: "SELF_EMPLOYED" },
      { name: "przędsiębiorca", value: "ENTREPRENEUR" },
    ],
    key: 'professionalStatus',
  },
  {
    name: 'E. Wykonywany zawód',
    options: [],
    key: 'professional',
  },
];

const MyProfile = ({ closeProfile }) => {
  const backendClient = useMemo(() => new BackendClient(), []);
  const userId = localStorage.getItem('userId');
  const profileId = localStorage.getItem('profileId');
  const [demAnswers, setDemAnswers] = useState({
    ageGroup: "",
    gender: "",
    professionalStatus: "",
    professional: "",
  });
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [success, setSuccess] = useState(false);

  const handleName = (e) => {
    setUserName(e.target.value);
  };
  const handleEmail = (e) => {
    setUserEmail(e.target.value);
  };

  const saveAnswer = (key, value) => {
    setDemAnswers(prevAnswers => ({
      ...prevAnswers,
      [key]: value
    }));
  };

  const handleTextareaChange = (key, event) => {
    saveAnswer(key, event.target.value);
  };

  const areDemAnswersValid = () => {
    for (let key in demAnswers) {
      if (demAnswers[key] === "") {
        return false;
      }
    }
    return true;
  };

  const getUser = useCallback(async () => {
    try {
      const response = await backendClient.getUserData(userId);
      setDemAnswers({
        ageGroup: response.ageGroup,
        gender: response.gender,
        professionalStatus: response.professionalStatus,
        professional: response.professional,
      });
      if (response.name) {
        setUserName(response.name);
      }
      setUserEmail(response.email);
    } catch (error) {
      console.log(error);
    }
  }, [backendClient, userId]);

  const submitUserChanges = async () => {
    if (areDemAnswersValid()) {
      try {
        await backendClient.changeUserName(profileId, userName);
        updateDemData(demAnswers, profileId);
        setSuccess(true);
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    getUser();
  }, [getUser])
  return (
    <div className='fixed top-0 left-0 w-[100vw] h-[100vh] bg-[#f5e6cf55] backdrop-blur-sm flex items-center justify-center z-30 text-black'>
      <div className='bg-white p-3 md:p-6 rounded-lg w-max h-max shadow-2xl mx-2'>
        <div className='w-full border-b border-lightBeige flex justify-end'>
          <RxCross2
            className='w-4 md:w-5 h-4 md:h-5 cursor-pointer mb-1 md:mb-2'
            onClick={() => closeProfile()}
          />
        </div>

        <div className='mt-2 md:mt-6 w-full'>
          <div className='flex gap-2 items-center'>
            <IoPersonOutline className='text-bronze h-5 w-5 md:h-6 md:w-6' />
            <h2 className='text-2xl md:text-3xl font-light'>Mój <span className='text-bronze'>profil</span></h2>
          </div>
          <div className='w-1/4 border-b-2 border-bronze mt-2 md:mt-4'></div>
        </div>

        {success ? (
          <div className='md:h-max w-full flex flex-col my-6 md:my-24 md:mx-10 flex justify-center'>
          <p className='text-3xl md:text-4xl font-light'>Dane profilu zostały <span className='text-bronze'>zmienione</span>!</p>
          <div className='flex'>
          </div>
        </div>
        ) : (
          <div>
          <div className='w-full border border-tan rounded mt-2 md:mt-4'>
            <div className='bg-lightBeige rounded border-tan p-1 text-sm'>
              Twoje dane
            </div>
            <div className='px-2'>
              <div className='relative mt-2'>
                <IoPersonCircleOutline className='absolute top-[25%] left-1 text-bronze' />
                <input
                  type="text"
                  className='border border-bronze w-full rounded pl-6 py-1 font-light text-xs focus:outline-none focus:border-maroon'
                  placeholder='Imię'
                  value={userName}
                  onChange={handleName}
                />
              </div>
              <div className='relative my-2'>
                <IoMailOutline className='absolute top-[25%] left-1 text-bronze' />
                <input
                  type="text"
                  readOnly={true}
                  className='border border-bronze w-full rounded pl-6 py-1 font-light text-xs focus:outline-none'
                  placeholder='Twój email'
                  value={userEmail}
                  onChange={handleEmail}
                />
              </div>
            </div>
          </div>

          <div className='w-full border border-tan rounded mt-2 md:mt-4'>
            <div className='bg-lightBeige rounded border-tan p-1 text-sm'>
              Twoje dane demograficzne
            </div>
            <div className='px-2 text-xs font-light'>
              {demDataInitial.map((item, ind) => (
                <div className='flex items-start mt-3' key={ind}>
                  <p className='font-light text-xs md:text-sm mb-2 w-1/4 flex'>{item.name}</p>
                  {item.options.length > 0 ? (
                    <div className='flex flex-wrap gap-4 mt-1 w-3/4'>
                      {item.options.map((opt, index) => (
                        <div className='flex items-center gap-2' key={opt.value}>
                          <label htmlFor={`radio-${ind}-${index}`} className='w-4 h-4 border-[2px] border-bronze rounded flex items-center justify-center'>
                            <input
                              type="radio"
                              name={`radio-${item.key}`}
                              className='hidden'
                              id={`radio-${ind}-${index}`}
                              checked={demAnswers[item.key] === opt.value}
                              onChange={() => saveAnswer(item.key, opt.value)}
                            />
                            {demAnswers[item.key] === opt.value ? (
                              <FaCheck className='text-bronze w-4 h-4' />
                            ) : (
                              <FaCheck className='text-transparent w-4 h-4' />
                            )}
                          </label>
                          <p className='text-xs font-light'>{opt.name}</p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <textarea
                      className='w-full border-[2px] border-bronze rounded p-2 mb-2 text-sm focus:outline-none focus:border-maroon'
                      value={demAnswers[item.key] || ''}
                      onChange={(e) => handleTextareaChange(item.key, e)}
                      rows={3}
                      placeholder='Twoja odpowiedź'
                    />
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className='flex gap-2 justify-end text-xs mt-2 md:mt-4'>
            <div
              onClick={() => closeProfile()}
              className='py-2 px-3 text-maroon hover:text-maroonHover animateSl'>Auluj</div>
            <div
              onClick={() => submitUserChanges()}
              className='py-2 px-3 text-white bg-maroon rounded hover:bg-maroonHover animateSl'>
              Zapisz zmiany
            </div>
          </div>
        </div>
        )}
      </div>
    </div>
  )
}

export default MyProfile
