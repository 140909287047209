import React, { useCallback, useEffect, useMemo, useState } from 'react';
import profileReview from '../../img/profileReview.png'
import { FaRegStar } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { FaStarHalfStroke } from "react-icons/fa6";
import { AiOutlineLike } from "react-icons/ai";
import { BackendClient } from '../../api/api';
import { FaRegComments } from "react-icons/fa6";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { VscSend } from "react-icons/vsc";

const renderStars = (rating) => {
  const totalStars = 5;
  const roundedRating = Math.round(rating * 2) / 2;
  const stars = [];

  for (let i = 0; i < totalStars; i++) {
    if (i < roundedRating) {
      if (i + 0.5 === roundedRating) {
        stars.push(<FaStarHalfStroke key={i} className="w-5 h-5 text-bronze" />);
      } else {
        stars.push(<FaStar key={i} className="w-5 h-5 text-bronze" />);
      }
    } else {
      stars.push(<FaRegStar key={i} className="w-5 h-5 text-bronze" />);
    }
  }

  return stars;
};

function formatDateToPolish(dateString) {
  const date = new Date(dateString);
  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };
  const formattedDate = new Intl.DateTimeFormat('pl-PL', options).format(date);
  return formattedDate;
}

const SingleReview = ({ review }) => {
  const backendClient = useMemo(() => new BackendClient(), []);
  const [comments, setComments] = useState([]);
  const { id, userName, addedAt, average, opinion } = review;
  const [showComments, setShowComments] = useState(false);
  const [showAddComm, setShowAddComm] = useState(false);
  const [addCommText, setAddCommText] = useState('');
  const userId = localStorage.getItem('userId');

  const handleAddCommText = (e) => {
    setAddCommText(e.target.value);
  }

  const handleShowAddComm = () => {
    setShowAddComm(!showAddComm);
  }

  const handleShowComments = () => {
    setShowComments(!showComments);
  }

  const getReviewsData = useCallback(async () => {
    try {
      const response = await backendClient.getComment(id);
      setComments(response);
    } catch (error) {
      console.log(error);
    }
  }, [backendClient, id]);

  const submitComment = useCallback(async (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();

      if (addCommText.trim() !== '') {
        try {
          await backendClient.addComment({
            opinionId: id,
            userId: userId,
            text: addCommText
          });

          setAddCommText('');
          getReviewsData();
          setShowAddComm(false);
        } catch (error) {
          console.log('Error submitting comment:', error);
        }
      } else {
        console.log('Comment is empty. Not submitting.');
      }
    }
  }, [backendClient, addCommText, getReviewsData, id, userId])

  useEffect(() => {
    getReviewsData();
  }, [getReviewsData])

  return (
    <div className='flex mt-6 border-b border-bronze pb-2'>
      <div className='w-[15%] pr-1'>
        <img
          src={profileReview}
          alt="profileReview"
          className='h-[100px]'
        />
      </div>
      <div className='w-[85%]'>

        <div className='flex justify-between'>
          <h2 className='font-semibold text-xl'>{userName}</h2>
          <p className='font-light text-sm'>{formatDateToPolish(addedAt)}</p>
        </div>

        <div className='flex items-center gap-1 mt-2'>
          <div className='bg-bronze rounded p-1 text-white text-sm mr-1 min-w-6 flex justify-center items-center gap-1'>
            <AiOutlineLike className='w-5 h-5' />
            <p>{average}</p>
          </div>
          {renderStars(review.average)}
        </div>

        <p className='font-light text-sm mt-2 min-h-[80px]'>{opinion}</p>

        <div className='flex justify-between mt-2 font-light'>
          <div
            onClick={() => handleShowComments()}
            className='flex jutify-center items-center gap-1 hover:text-bronze animateSl'>
            <FaRegComments className='w-5 h-5 text-bronze' />
            <p>Komentarze ({comments.length})</p>
            {
              showComments
                ? <IoIosArrowUp className='w-5 h-5 text-bronze' />
                : <IoIosArrowDown className='w-5 h-5 text-bronze' />
            }
          </div>
          <div
            onClick={() => handleShowAddComm()}
            className='flex items-center justify-center gap-1 hover:text-bronze animateSl'>
            <AiOutlinePlusCircle className='text-bronze w-5 h-5' />
            <p>Dodaj komentarz</p>
          </div>
        </div>
        {showComments && (
          <>
            {comments.map((comm, index) => (
              <div key={`comm-${index}`} className='mt-4'>
                <div className='flex justify-between'>
                  <h2 className=''>{comm.userName}</h2>
                  <p className='font-light text-sm'>{formatDateToPolish(comm.addedAt)}</p>
                </div>
                <p className='text-sm font-light mt-1'>{comm.text}</p>
              </div>
            ))}
          </>
        )}
        {showAddComm && (
          <div className='relative mt-2'>
            <textarea
              name="addComm"
              rows="3"
              onChange={handleAddCommText}
              onKeyDown={submitComment}
              value={addCommText}
              className='p-2 border-2 border-bronze rounded font-light w-full focus:outline-none focus:border-maroon min-h-[40px]'
              placeholder='Napisz komentarz'
            ></textarea>
            < VscSend className='absolute right-2.5 bottom-4 text-[#00000044] w-5 h-5' />
          </div>
        )}
      </div>
    </div>
  )
}

export default SingleReview
