import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SlArrowDown } from "react-icons/sl";
import { BackendClient } from '../../api/api';


const Answers = () => {
  const profileId = localStorage.getItem('profileId');
  const [openBasic, setOpenBasic] = useState(false);
  const [openExt, setOpenExt] = useState(false);
  const [bsSurvey, setBsSurvey] = useState(null);
  const [exSurvey, setExSurvey] = useState(null);
  const backendClient = useMemo(() => new BackendClient(), []);


  const toggleBasic = () => {
    setOpenBasic(!openBasic);
  };

  const toggleExt = () => {
    setOpenExt(!openExt);
  };

  const getBasicSurvey = useCallback(async () => {
    try {
      const responceB = await backendClient.getSurvey(profileId, '3');
      const responceE = await backendClient.getSurvey(profileId, '4');
      setBsSurvey(responceB);
      setExSurvey(responceE);
    } catch (error) {
      console.log(error);
    }
  }, [backendClient, profileId]);

  useEffect(() => {
    getBasicSurvey();
  }, [getBasicSurvey])

  return (
    <div className='font-light mb-16 md:mb-0'>
      <h1 className='font-semibold text-lg'>Odpowiedzi ankiet</h1>
      <h2>W tej zakładce znajdują się odpowiedzi do pytań, które udzieliłeś w ankietach.</h2>

      {/* Basic Survey Section */}
      <div>
        <div>
          <div>
            {bsSurvey && (
              <div
              className={`w-full flex items-center animateSl hover:bg-[#C29E7366] p-1 mt-2 ${openBasic && 'bg-[#C29E7366]'}`}
              onClick={toggleBasic}
            >
              <p className='w-[5%]'>1</p>
              <p className='w-[20%]'>{bsSurvey.date}</p>
              <p className='w-[25%]'>{bsSurvey.surveyName}</p>
              <div className='w-1/2 flex justify-end items-center cursor-pointer'>
                <SlArrowDown className={`mr-2 w-4 h-4 transform transition-transform ${openBasic ? 'rotate-180' : ''}`} />
              </div>
            </div>
            )}

            {openBasic && (
              <div>
                <div className='w-full flex border-b border-[#ccc] p-1 mt-2'>
                  <p className='w-[20%]'>Nr pytania</p>
                  <p className='w-[35%]'>Treść pytania</p>
                  <p className='w-[42%] pl-[3%]'>Twoja odpowiedź</p>
                </div>
                {bsSurvey.questionsResult.map((item, index) => (
                  <div className='w-full flex border-b border-[#ccc] p-1' key={`basic-${index}`}>
                    <p className='w-[20%]'>{index + 1}</p>
                    <p className='w-[35%]'>{item.question}</p>
                    <p className='w-[42%] pl-[3%]'>{item.answer}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>


        {/* Extension Survey Section */}
        {exSurvey && (
          <div
          className={`w-full flex items-center animateSl hover:bg-[#C29E7366] p-1 mt-2 ${openExt && 'bg-[#C29E7366]'}`}
          onClick={toggleExt}
        >
          <p className='w-[5%]'>2</p>
          <p className='w-[20%]'>{exSurvey.date}</p>
          <p className='w-[25%]'>{exSurvey.surveyName}</p>
          <div className='w-1/2 flex justify-end items-center cursor-pointer'>
            <SlArrowDown className={`mr-2 w-4 h-4 transform transition-transform ${openExt ? 'rotate-180' : ''}`} />
          </div>
        </div>
        )}

        {openExt && (
          <div>
            <div className='w-full flex border-b border-[#ccc] p-1 mt-2'>
              <p className='w-[20%]'>Nr pytania</p>
              <p className='w-[35%]'>Treść pytania</p>
              <p className='w-[42%] pl-[3%]'>Twoja odpowiedź</p>
            </div>
            {exSurvey.questionsResult.map((item, index) => (
              <div className='w-full flex border-b border-[#ccc] p-1' key={`ext-${index}`}>
                <p className='w-[20%]'>{index + 1}</p>
                <p className='w-[35%]'>{item.question}</p>
                <p className='w-[42%] pl-[3%]'>{item.answer}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Answers;
