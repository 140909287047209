import React from 'react'
import gnomek from '../../img/gnomek.png';
import dialog from '../../img/dialog.png';
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const HomeBT = () => {
  return (
    <div className='w-full full-height-minus-5rem bg-lightBeige flex items-center justify-center overflow-hidden'>
      <div className='h-[500px] w-[800px] flex'>
        <div className='h-full w-2/5 relative hidden sm:block'>
          <div className='w-[70%]'>
            <img
              src={dialog}
              alt="dialog"
            />
            <p className='absolute top-3 left-8 text-lg font-medium text-center'>
              Do dzieła!<br />
              <div className='text-sm flex text-center leading-5 mt-1'>Teraz musisz powiedzieć<br /> mi coś o sobie.</div>
            </p>
          </div>
          <img
            src={gnomek}
            alt="gnomek"
            className='absolute w-72 top-32 left-[100px] z-10 rotate-[-25deg] animate-rotate-in-down-right'
          />
        </div>
        <div className='h-full w-3/5 border-l-2 border-bronze bg-lightBeige px-8 relative z-20 flex flex-col items-left justify-center ml-10 sm:ml-0'>
          <div>
            <h1 className='text-5xl'>
              Miło <span className='font-semibold text-bronze'>Cię</span> widzieć!
            </h1>
            <div className='w-3/4 border-b-2 border-bronze my-4'></div>
          </div>
          <p className='text-xl'>
            Odpowiedz na 6 krótkich pytań, dzięki którym sprawdzimy, czy jesteś gotowy na przygodę z ProRokiem!
          </p>
          <Link
            className='mt-6 h-10 w-max bg-maroon flex items-center px-6 rounded font-semibold gap-4 cursor-pointer hover:bg-[#A63C43] transition ease-in-out duration-300 text-white'
            to={'/basic-test'}
          >
            <p>
              Zaczynamy
            </p>
            <FaArrowRightLong />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default HomeBT
