import React, { useEffect, useState } from 'react'
import ResultWait from '../basicTest/ResultWait';
import ResultETFinal from './ResultETFinal';

const ResultET = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);
  return (
    <div>
      {loading ? (
        <ResultWait />
      ) : (
        <ResultETFinal/>
      )}
    </div>
  )
}

export default ResultET
