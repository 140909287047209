import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner'
import CourseBox from '../courses/CourseBox';
import { BackendClient } from '../../api/api';


const courseListCat = [
  { key: 'MATCHED', value: 'Przypisane' },
  { key: 'IN_PROGRESS', value: 'W trakcie' },
  { key: 'FOR_LATER', value: 'Kursy na później' },
  { key: 'COMPLETED', value: 'Zakończone' },
];

const MyCourses = () => {
  const backendClient = useMemo(() => new BackendClient(), []);
  const [currentCourseCat, setCurrentCourseCat] = useState('MATCHED');
  const userId = localStorage.getItem('userId');
  const [currentCourseCatLoading, setCurrentCourseCatLoading] = useState(true);
  const [courses, setCourses] = useState([]);

  const getCourses = useCallback(async () => {
    try {
      const response = await backendClient.getCoursesMatched(userId, currentCourseCat);
      if (response[0].courses || response.length > 0) {
        setCourses(response[0].courses);
      } else {
        setCourses([]);
      }
    } catch (error) {
      console.log('error:', error);
    }
  }, [userId, currentCourseCat, backendClient]);

  const changeCurrentCourseCat = (course) => {
    setCurrentCourseCatLoading(true);
    setCurrentCourseCat(course);
    setTimeout(() => {
      setCurrentCourseCatLoading(false);
    }, 1000);
  }

  useEffect(() => {
    getCourses();
    setTimeout(() => {
      setCurrentCourseCatLoading(false);
    }, 1500);
  }, [currentCourseCat, getCourses]);
  return (
    <>
      <div className='flex items-center border-b border-[#AA863944]'>
        {courseListCat.map(course => (
          <div
            key={course.key}
            className={`text-xs border-b-2 ${course.key === currentCourseCat ? `font-medium border-bronze` : `font-light border-transparent hover:border-gold`} px-2 md:px-6 py-1 transition ease-in-out duration-300 cursor-pointer flex justify-center items-center`}
            onClick={() => changeCurrentCourseCat(course.key)}
          >
            {course.value}
          </div>
        ))}
      </div>
      {currentCourseCatLoading ? (
        <div className='w-full flex justify-center '>
          <ThreeDots
            visible={true}
            height="450"
            width="200"
            color="#D1BA5C"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className='w-full flex flex-col  justify-center mt-4 mb-24 md:mb-0'>
          {(currentCourseCat === "MATCHED") && (
            <div>
              <p className='ml-2 text-lg text-bronze'>
                Najlepiej dopasowane kursy dla Ciebie to:
              </p>
             
            </div>
          )}
          {(currentCourseCat === "IN_PROGRESS") && (
            <div>
               <p className='font-semibold'>Twoje kursy w trakcie</p>
              <p className='font-light'>W tej zakładce znajdują się kursy, które własie realizujesz.</p>
            </div>
          )}
          {(currentCourseCat === "FOR_LATER") && (
            <div>
              <p className='font-semibold'>Kursy, które Ci się spodobały</p>
              <p className='font-light'>Dodaj interesujące kursy na później, dzięki czemu szybko do nich trafisz.</p>
            </div>
          )}
          {(currentCourseCat === "COMPLETED") && (
            <div>
              <p className='font-semibold'>Twoje zakończone kursy</p>
              <p className='font-light'>W tej zakładce znajdują zakończone przez Ciebie kursy. Pozwól nam się rozwijać i dziel się swoją opinią, na temat kursów!</p>
            </div>
          )}
          <div className='flex flex-wrap w-full'>
            {courses.length > 0 ? (
              <>
                {courses.map(course => (
                  <>
                  {currentCourseCat === "MATCHED" ? (
                    <div key={course.id}>
                    <CourseBox course={course} isCompact={false} />
                  </div>
                  ) : (
                    <CourseBox course={course} isCompact={true} />
                  )}
                  </>
                ))}
              </>
            ) : (
              <div className='flex w-full justify-center text-xl mt-4'>
                Nie masz kursów tej kategorii
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default MyCourses
