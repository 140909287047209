import React from 'react'
import gnomek from '../../img/gnomek.png';
import dialog from '../../img/dialog.png';
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const ResultETFinal = () => {
  const { profileId } = useParams();

  return (
    <div className='w-full full-height-minus-5rem bg-lightBeige flex flex-col items-center justify-center'>
      <div className='h-auto lg:h-[500px] w-90vw flex lg:w-[1000px]'>

        <div className='h-full w-2/5 relative hidden sm:block'>

          {/* Dialog block */}
          <div className='w-[70%] ml-10'>
            <img
              src={dialog}
              alt="dialog"
            />
            <p className='absolute top-3 left-[20%] text-lg font-medium text-center'>
              Huraaaa!<br />
              <div className='text-sm flex text-center leading-5 mt-1'>
                Pora na przygodę, w świat<br />nauki!
              </div>
            </p>
          </div>

          {/* Gnomek */}
          <img
            src={gnomek}
            alt="gnomek"
            className='absolute w-72 top-32 left-[40%] z-10 rotate-[-25deg] animate-rotate-in-down-right'
          />
        </div>


        <div className='h-full w-full sm:w-3/5 border-l-2 border-bronze bg-lightBeige px-8 relative z-20 flex flex-col items-left justify-center'>

          {/* Header */}
          <div>
            <h1 className='text-5xl'>
              Wyniki już są <span className='font-semibold text-bronze'>Gotowe!</span>
            </h1>
            <div className='w-3/4 border-b-2 border-bronze my-4'></div>
          </div>

          {/* Result text */}
          <p className='text-2xl font-light'>
            <>
              <span className='font-medium'>Nie mogę się już doczekać co Ty na to! Wyniki dostępne są po zalogowaniu.</span>
              <br /> <br />
              Jeśli masz już konto, po prostu się zaloguj.
              <br /> <br />
              Nie masz jeszcze konta?
              Załóż konto w naszym systemie i ciesz się nieograniczonym dostępem do ProRoka!
            </>
          </p>

          <div className='mt-4 sm:mt-16 flex gap-10 mx-auto'>
            <Link
              className='w-max px-6 flex items-center rounded font-medium gap-4 cursor-pointer hover:text-bronze transition ease-in-out duration-300 text-maroon'
              to={`/login`}
            >
              <p>
                Zaloguj się
              </p>
            </Link>
            <Link
              className='h-10 w-max bg-maroon flex items-center px-6 rounded font-normal gap-4 cursor-pointer hover:bg-[#A63C43] transition ease-in-out duration-300 text-white'
              to={`/register/${profileId}`}
            >
              <p>
                Zarejestruj się
              </p>
              <FaArrowRightLong />
            </Link>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ResultETFinal
